import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { useUpdateNotificationStatusMutation } from "../../../services/userApi";
import "./NotificationModal.css";

const NotificationModal = ({
  isOpen,
  onClose,
  notifications,
  setNotification,
  style,
}) => {
  const navigate = useNavigate();
  const [updateNotificationStatus] = useUpdateNotificationStatusMutation();

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const handleSwipe = async (notificationId, dir) => {
    const notificationElement = document.getElementById(notificationId);
    if (notificationElement) {
      notificationElement.classList.add(`swiped-${dir}`);
    }

    setTimeout(async () => {
      await updateNotificationStatus({ id: notificationId });
      const updatedNotifications = notifications.filter(
        (notification) => notification._id !== notificationId
      );
      setNotification(updatedNotifications);
    }, 300);
  };

  const handleClick = async (notificationId, redirectUrl) => {
    await updateNotificationStatus({ id: notificationId });
    navigate(`/home/${redirectUrl}`);
  };

  const handleTouchStart = (e, notificationId) => {
    const touch = e.touches[0];
    e.currentTarget.dataset.swipeStart = touch.clientX;
    e.currentTarget.dataset.swipeStartY = touch.clientY;
  };

  const handleTouchEnd = (e, notificationId) => {
    const touch = e.changedTouches[0];
    const swipeEnd = touch.clientX;
    const swipeEndY = touch.clientY;
    const swipeStart = parseFloat(e.currentTarget.dataset.swipeStart);
    const swipeStartY = parseFloat(e.currentTarget.dataset.swipeStartY);
    const swipeDistance = swipeEnd - swipeStart;
    const swipeVerticalDistance = Math.abs(swipeEndY - swipeStartY);

    // Check if the swipe is mostly horizontal
    if (swipeVerticalDistance < 50) {
      if (swipeDistance < -50) {
        handleSwipe(notificationId, "left");
      } else if (swipeDistance > 50) {
        handleSwipe(notificationId, "right");
      }
    }
  };

  const handleMouseDown = (e, notificationId) => {
    e.currentTarget.dataset.swipeStart = e.clientX;
    e.currentTarget.dataset.swipeStartY = e.clientY;
  };

  const handleMouseUp = (e, notificationId) => {
    const swipeEnd = e.clientX;
    const swipeEndY = e.clientY;
    const swipeStart = parseFloat(e.currentTarget.dataset.swipeStart);
    const swipeStartY = parseFloat(e.currentTarget.dataset.swipeStartY);
    const swipeDistance = swipeEnd - swipeStart;
    const swipeVerticalDistance = Math.abs(swipeEndY - swipeStartY);

    // Check if the swipe is mostly horizontal
    if (swipeVerticalDistance < 50) {
      if (swipeDistance < -50) {
        handleSwipe(notificationId, "left");
      } else if (swipeDistance > 50) {
        handleSwipe(notificationId, "right");
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Notifications"
      className="notification-modal"
      overlayClassName="notification-modal-overlay"
      style={{ content: style }}
      ariaHideApp={false}
    >
      <div className="notification-header">
        <h2>Notifications</h2>
        <button className="notification-modal-close-button" onClick={onClose}>
          <svg viewBox="0 0 24 24" width="22" height="22">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        </button>
      </div>
      <div className="notifications-content">
        {notifications?.length ? (
          notifications.map((notification) => (
            <div
              key={notification._id}
              id={notification._id}
              className="notification-item"
              onTouchStart={(e) => handleTouchStart(e, notification._id)}
              onTouchEnd={(e) => handleTouchEnd(e, notification._id)}
              onMouseDown={(e) => handleMouseDown(e, notification._id)}
              onMouseUp={(e) => handleMouseUp(e, notification._id)}
              onClick={() =>
                handleClick(
                  notification._id,
                  notification.notificationRedirectLocation
                )
              }
            >
              <div className="notification-icon">
                <svg viewBox="0 0 24 24" width="24" height="24">
                  <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
                </svg>
              </div>
              <div className="notification-content">
                <div className="notification-message">
                  {notification.notificationContent}
                </div>
                <div className="notification-date">
                  {formatDate(notification.createdAt)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-notifications">
            <svg viewBox="0 0 24 24" width="48" height="48">
              <path d="M12 6.5c-2.49 0-4 2.02-4 4.5v6h8v-6c0-2.48-1.51-4.5-4-4.5zm4 10.5H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
            <p>No notifications available.</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NotificationModal;
