import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const withdrawApi = createApi({
  reducerPath: "withdrawApi",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: "cors",
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["User", "Task", "Profile", "leaderBoard", "Wallet"], // automatic-data fetching
  endpoints: (builder) => ({
    getMinimumWithdrawAmount: builder.query({
      query: () => "/secure/get-minimum-withdraw-amount",
      providesTags: ["Withdraw"],
    }),
    getWithdrawChargeAmount: builder.query({
      query: () => "/secure/get-withdraw-charge-amount",
      providesTags: ["Withdraw"],
    }),
    withdrawHistory: builder.query({
      query: ({ page, limit }) =>
        `/secure/withdrawals?page=${page}&limit=${limit}`,
      providesTags: ["Withdraw"],
    }),
    withdraw: builder.mutation({
      query: (body) => ({
        url: "/secure/withdraw",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Withdraw"],
    }),
    getBank: builder.query({
      query: () => "/secure/fetch_payment_details",
      providesTags: ["Bank"],
    }),
    getUPI: builder.query({
      query: () => "/secure/fetch_upi",
      providesTags: ["Bank"],
    }),
    getWallet: builder.query({
      query: () => "/secure/get-wallet",
      providesTags: ["Wallet"],
    }),
  }),
});

export const {
  useGetMinimumWithdrawAmountQuery,
  useGetWithdrawChargeAmountQuery,
  useWithdrawHistoryQuery,
  useWithdrawMutation,
  useGetBankQuery,
  useGetUPIQuery,
  useGetWalletQuery,
} = withdrawApi;
