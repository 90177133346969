import React from "react";
import "./BottomMenu.css";
import { FaHome, FaBullhorn } from "react-icons/fa";
import { RiTodoFill, RiMegaphoneLine, RiSettings3Line } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";

const Menus = [
  {
    id: "sf9w6rsf",
    name: "Promote",
    icon: <RiMegaphoneLine />,
    route: "/home/promote",
  },
  {
    id: "sf9w6rsf",
    name: "Manage",
    icon: <RiSettings3Line />,
    route: "/home/manage-created-tasks",
  },
  {
    id: "158w8r5sf",
    name: "Home",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        height="24px"
        width="24px"
        fill="#fff"
      >
        <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)">
          <path d="M 87.998 40.831 c -0.387 0 -0.778 -0.112 -1.122 -0.346 L 45 12.023 L 3.124 40.485 c -0.913 0.622 -2.157 0.383 -2.778 -0.53 c -0.621 -0.913 -0.383 -2.157 0.53 -2.778 l 43 -29.226 c 0.678 -0.461 1.57 -0.461 2.248 0 l 43 29.226 c 0.914 0.621 1.151 1.865 0.53 2.778 C 89.268 40.524 88.639 40.831 87.998 40.831 z" />
          <path d="M 76.962 85.395 H 13.039 c -1.104 0 -2 -0.896 -2 -2 V 40.991 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 40.403 h 59.923 V 40.991 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 42.403 C 78.962 84.499 78.066 85.395 76.962 85.395 z" />
          <path d="M 76.962 23.319 c -1.104 0 -2 -0.896 -2 -2 v -6.094 h -9.01 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 11.01 c 1.104 0 2 0.896 2 2 v 8.094 C 78.962 22.423 78.066 23.319 76.962 23.319 z" />
          <path d="M 39.373 75.782 c -1.104 0 -2 -0.896 -2 -2 V 47.774 h -10.65 v 26.008 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 V 45.774 c 0 -1.104 0.896 -2 2 -2 h 14.65 c 1.104 0 2 0.896 2 2 v 28.008 C 41.373 74.887 40.478 75.782 39.373 75.782 z" />
          <path d="M 65.276 61.778 H 50.627 c -1.104 0 -2 -0.896 -2 -2 V 45.774 c 0 -1.104 0.896 -2 2 -2 h 14.649 c 1.104 0 2 0.896 2 2 v 14.004 C 67.276 60.883 66.381 61.778 65.276 61.778 z M 52.627 57.778 h 10.649 V 47.774 H 52.627 V 57.778 z" />
        </g>
      </svg>
    ),
    route: "/home",
  },
  {
    id: "sf4sf5w8r",
    name: "Tasks",
    icon: (
      <svg height="24px" width="22px" viewBox="0 0 470.767 470.767">
        <g fill="#fff">
          <path d="M362.965,21.384H289.62L286.638,7.99C285.614,3.323,281.467,0,276.685,0h-82.618c-4.782,0-8.913,3.323-9.953,7.99l-2.967,13.394h-73.36c-26.835,0-48.654,21.827-48.654,48.662v352.06c0,26.835,21.819,48.662,48.654,48.662h255.179c26.835,0,48.67-21.827,48.67-48.662V70.046C411.635,43.211,389.8,21.384,362.965,21.384z M379.831,422.105c0,9.295-7.563,16.858-16.866,16.858H107.786c-9.287,0-16.85-7.563-16.85-16.858V70.046c0-9.295,7.563-16.857,16.85-16.857h66.294l-1.692,7.609c-0.684,3.02,0.062,6.188,1.988,8.596c1.94,2.415,4.876,3.82,7.965,3.82h106.082c3.091,0,6.026-1.405,7.951-3.82c1.942-2.415,2.687-5.575,2.004-8.596l-1.692-7.609h66.279c9.303,0,16.866,7.563,16.866,16.857V422.105z" />
          <path d="M170.835,188.426h43.249l-10.279-7.019c-14.506-9.899-18.232-29.693-8.325-44.197c9.893-14.489,29.693-18.239,44.197-8.324l1.694,1.157v-12.136c0-7.866-6.383-14.248-14.242-14.248h-56.294c-7.857,0-14.24,6.383-14.24,14.248v56.271C156.595,182.045,162.978,188.426,170.835,188.426z" />
          <path d="M303.256,110.313l-49.85,47.194l-22.704-15.49c-7.221-4.962-17.13-3.083-22.099,4.162c-4.954,7.251-3.09,17.144,4.178,22.098l33.28,22.727c2.718,1.864,5.839,2.772,8.961,2.772c3.96,0,7.888-1.474,10.933-4.356l59.167-56.014c6.382-6.033,6.645-16.104,0.62-22.479C319.686,104.552,309.637,104.28,303.256,110.313z" />
          <path d="M170.835,297.669H214.1l-10.295-7.027c-14.506-9.901-18.232-29.693-8.325-44.197c9.893-14.498,29.693-18.248,44.197-8.325l1.694,1.158v-12.136c0-7.865-6.383-14.248-14.242-14.248h-56.294c-7.857,0-14.24,6.383-14.24,14.248v56.279C156.595,291.286,162.978,297.669,170.835,297.669z" />
          <path d="M303.256,219.555l-49.85,47.186l-22.704-15.49c-7.221-4.97-17.13-3.098-22.099,4.162c-4.954,7.253-3.09,17.144,4.178,22.099l33.28,22.727c2.718,1.864,5.839,2.772,8.961,2.772c3.96,0,7.888-1.476,10.933-4.356l59.167-56.007c6.382-6.033,6.645-16.096,0.62-22.479C319.686,213.793,309.637,213.529,303.256,219.555z" />
          <path d="M227.129,322.135h-56.294c-7.857,0-14.24,6.383-14.24,14.248v56.271c0,7.865,6.383,14.248,14.24,14.248h56.294c7.859,0,14.242-6.383,14.242-14.248v-56.271C241.371,328.518,234.988,322.135,227.129,322.135z" />
        </g>
      </svg>
    ),
    route: "/home/task",
  },

  {
    id: "sf9w6rsf",
    name: "Leader Board",
    icon: (
      <img
        src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1723102294/TaskPlanet/le_nl41xy.png"
        alt="Leader Board"
        style={{ width: "25px", height: "25px" }}
      />
    ),
    route: "/home/leader-board",
  },
];

const BottomMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="footer-container">
      {Menus.map((menu) => {
        const isSelected =
          location.pathname === menu.route ||
          (location.pathname === "/home" && menu.name === "Home");

        return (
          <div key={menu.id} className="footer-item-container">
            <button
              className="footer-item"
              onClick={() => navigate(menu.route)}
              aria-label={menu.name}
            >
              <div
                className={`footer-icon-wrapper ${
                  isSelected ? "selected" : ""
                }`}
              >
                {React.cloneElement(menu.icon, { size: 25, color: "#fff" })}
                {isSelected && (
                  <>
                    <div className="footer-item-before"></div>
                    <div className="footer-item-after"></div>
                  </>
                )}
              </div>
              {isSelected && (
                <div className="footer-item-text">{menu.name}</div>
              )}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default BottomMenu;
