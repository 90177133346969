import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { env } from "../env";
import { getLocalStorage } from "../utils/function/localStorage";

export const SocialMediaTask = createApi({
  reducerPath: "socialMediaTask",
  baseQuery: fetchBaseQuery({
    baseUrl: env.BASE_URL,
    // mode: 'cors',
    prepareHeaders: (headers) => {
      headers.set("X-Auth-Token", getLocalStorage("tp_Aa_uth_access"));
      return headers;
    },
  }),
  tagTypes: ["socialMediaTask"], // automatic-data fetching
  endpoints: (builder) => ({
    // Upload KYC
    addSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/create-social-media-task",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    getAllSocialMediaTask: builder.query({
      query: (query) =>
        `/secure/get-all-my-social-media-task?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["socialMediaTask"], // automatic-data fetching
    }),
    showAndHideSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/hide-and-show-task",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    deleteSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/delete-task",
        method: "DELETE",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    updateStatusSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/update-task-status",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    updateSocialMediaTask: builder.mutation({
      query: (body) => ({
        url: "/secure/update-task",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    getAllSocialMediaTaskHistory: builder.query({
      query: (query) =>
        `/secure/get-all-submit-my-social-media-task?page=${query.page}&limit=${query.limit}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["socialMediaTask"], // automatic-data fetching
    }),
    updateSubmitSocialMediaTaskStatus: builder.mutation({
      query: (body) => ({
        url: "/secure/update-submit-social-media-task",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    getSubTitle: builder.query({
      query: (query) => `/get_sub_title?subTitle=${query}`,
      providesTags: ["socialMediaTask"], // automatic-data fetching
    }),
    openAndCloseTask: builder.mutation({
      query: (body) => ({
        url: "/common/OpenAndCloseTask",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    submitExtend: builder.mutation({
      query: (body) => ({
        url: "/secure/submit_extend",
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    handleCloseBtn: builder.mutation({
      query: (body) => ({
        url: "/common/handleCloseBtn",
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["socialMediaTask"],
    }),
    getAllSpecificSocialMediaTaskHistory: builder.query({
      query: (query) =>
        `/common/get_specific_social_media_task_history?page=${query.page}&limit=${query.limit}&task_id=${query.task_id}&searchById=${query.searchById}&startDate=${query.startDate}&endDate=${query.endDate}&csv=${query.csv}`,
      providesTags: ["socialMediaTask"], // automatic-data fetching
    }),
    getLimitForAutoFill: builder.query({
      query: (query) =>
        `/common/get_unique_limits_of_social_media_auto_fill?task_sub_profile=${query?.task_sub_profile}&task_profile=${query?.task_profile}`,
      providesTags: ["socialMediaTask"], // automatic-data fetching
    }),
    getLimitBaseSocialMediaTaskAutoFill: builder.mutation({
      query: (body) => ({
        url: "/common/get_limits_of_social_media_auto_fill",
        method: "PUT",
        body: body,
      }),
    }),
    getProfileData: builder.query({
      query: (query) => `/common/get_profile_data?searchTerm=${query}`,
    }),
    getSubProfileData: builder.query({
      query: (query) =>
        `/common/get_sub_profile_data?searchTerm=${query?.term}&profile=${query.profile}`,
    }),
    getExtendTaskCount: builder.query({
      query: () => `/secure/get-extend-task-count`,
    }),
    loadMoreData: builder.mutation({
      query: (params) => ({
        url: `/secure/get-all-submit-my-social-media-task?page=${params.page}&limit=${params.limit}&searchById=${params.searchById}&startDate=${params.startDate}&endDate=${params.endDate}&csv=${params.csv}`,
        method: "GET",
        params: params,
      }),
    }),
    getMySmtCount: builder.query({
      query: () => `/secure/get-my-smt-count`,
    }),
    getMySmtSubmissionCount: builder.query({
      query: (status) => `/secure/get-my-smt-submission-count?status=${status}`,
    }),
    TaskCreationCost: builder.mutation({
      query: (query) => ({
        url: `/secure/taskCreationCost?admin_charge=${query.admin_charge}&limit=${query.limit}&point=${query.point}`,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useAddSocialMediaTaskMutation,
  useGetAllSocialMediaTaskQuery,
  useShowAndHideSocialMediaTaskMutation,
  useDeleteSocialMediaTaskMutation,
  useUpdateStatusSocialMediaTaskMutation,
  useUpdateSocialMediaTaskMutation,
  useGetAllSocialMediaTaskHistoryQuery,
  useUpdateSubmitSocialMediaTaskStatusMutation,
  useGetSubTitleQuery,
  useOpenAndCloseTaskMutation,
  useSubmitExtendMutation,
  useHandleCloseBtnMutation,
  useGetAllSpecificSocialMediaTaskHistoryQuery,
  useGetLimitForAutoFillQuery,
  useGetLimitBaseSocialMediaTaskAutoFillMutation,
  useGetProfileDataQuery,
  useGetSubProfileDataQuery,
  useGetExtendTaskCountQuery,
  useLoadMoreDataMutation,
  useGetMySmtCountQuery,
  useGetMySmtSubmissionCountQuery,
  useTaskCreationCostMutation,
} = SocialMediaTask;
