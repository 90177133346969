import React from "react";
import { useMyProfileQuery } from "../../../services/userApi";
// Import icons from react-icons
import { FiUser, FiLogOut, FiRocket } from "react-icons/fi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { SlInfo } from "react-icons/sl";
import { removeLocalStorage } from "../../../utils/function/localStorage";
import "./HeaderDropdown.css";
import { useNavigate } from "react-router-dom";
import { IoRocketOutline } from "react-icons/io5";
import pointsIcon from "../../../assets/images/Point.png";

const HeaderDropdown = ({ visible, setVisible }) => {
  const { data } = useMyProfileQuery();
  const navigate = useNavigate();

  // Calculate total available points
  const calculateAvailablePoints = () => {
    if (!data?.data[0]) return 0;
    const user = data.data[0];

    let points = 0;
    if (!user.isEmailVerified) points += 100;
    if (
      (user.country === "India" || user.country === undefined) &&
      !user.isMobileVerified
    )
      points += 100;
    if (!user.dob) points += 100;
    if (!user.gender) points += 100;
    if (!user.address) points += 100;
    if (!user.profile_image_status) points += 100;
    if (!user.isKYCVerified) points += 1000;
    if (!user.country) points += 100;

    return points;
  };

  const handleLogout = () => {
    removeLocalStorage("tp_Aa_uth_access");
    window.location.reload();
  };

  // Get container width based on membership status
  const getContainerWidth = () => {
    if (data?.data[0]?.is_premium_plus) return "290px";
    if (data?.data[0]?.is_premium) return "270px";
    return "242px";
  };

  return (
    <div
      className="headerDropdown-container"
      style={{
        top: visible ? "55px" : "-800px",
        width: getContainerWidth(),
      }}
    >
      <div className="headerDropdown-inner-container">
        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/my-profile");
            setVisible(false);
          }}
        >
          <div className="headerDropdown-profile-button-content">
            <span>
              <FiUser /> My Profile
            </span>
            {calculateAvailablePoints() > 0 && (
              <div className="headerDropdown-points-badge">
                <span>Get {calculateAvailablePoints()}</span>
                <img
                  src={pointsIcon}
                  alt="points"
                  className="headerDropdown-points-icon"
                />
              </div>
            )}
          </div>
          <span
            className="headerDropdown-membership-badge"
            style={{
              backgroundColor: data?.data[0]?.is_premium_plus
                ? "#e0aa3e"
                : data?.data[0]?.is_premium
                ? "#82CD47"
                : "#2f68fd",
            }}
          >
            {data?.data[0]?.is_premium_plus
              ? "Premium Plus"
              : data?.data[0]?.is_premium
              ? "Premium"
              : "Free"}
          </span>
        </button>

        {!data?.data[0]?.is_premium && !data?.data[0]?.is_premium_plus && (
          <button
            className="headerDropdown-item"
            onClick={() => {
              navigate("/home/premium");
              setVisible(false);
            }}
          >
            <span>
              <IoRocketOutline /> Activate Premium
            </span>
          </button>
        )}
        {!data?.data[0]?.is_premium_plus && (
          <button
            className="headerDropdown-item"
            onClick={() => {
              navigate("/home/premium");
              setVisible(false);
            }}
          >
            <span>
              <IoRocketOutline /> Activate Premium Plus
            </span>
          </button>
        )}

        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/help-and-support");
            setVisible(false);
          }}
        >
          <span>
            <TfiHeadphoneAlt /> Help and Support
          </span>
        </button>

        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/about");
            setVisible(false);
          }}
        >
          <span>
            <SlInfo /> About
          </span>
        </button>

        <button className="headerDropdown-item" onClick={handleLogout}>
          <span>
            <FiLogOut /> Sign Out
          </span>
        </button>
      </div>
    </div>
  );
};

export default HeaderDropdown;
