export const env = {
  // BASE_URL: "http://localhost:7001",
  // BASE_URL: "https://taskplanet.triplewsols.in",
  BASE_URL: "https://api.taskplanet.org",
  // BASE_URL: "https://api.development.taskplanet.org",
  firebase_dynamic_link_api:
    "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBSEKdyHEvOqcQaiPb4-jEmul4ZGVTTlno",
  google_client_id:
    "350224658302-etk8h8jcju1qbrjri8nrkd0uamgs7a62.apps.googleusercontent.com",
};
